import { Controller } from '@hotwired/stimulus'
import Dashboard from '@uppy/dashboard'
import ImageEditor from '@uppy/image-editor'
import { uploadedFileData, uppyInstance } from '../utils/uppy'
import { nanoid } from 'nanoid'

export default class extends Controller {
  static targets = ['input']
  static values = { types: Array, resource: String, max: String }

  connect() {
    this.uppy = this.createUppy()
  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
      id: this.inputTarget.id,
      types: this.typesValue,
      max: this.maxValue,
      autoProceed: false
    })
      .use(Dashboard, {
        target: this.inputTarget.parentNode,
        inline: true,
        height: 470,
        showProgressDetails: true,
        replaceTargetContent: true,
        hideProgressAfterFinish: true,
        autoOpen: true
      })
      .use(ImageEditor, {
        target: Dashboard,
        quality: 1, // for the resulting image, 0.8 is a sensible default
        cropperOptions: {
          viewMode: 2,
          background: true,
          autoCropArea: 1,
          responsive: true,
          aspectRatio: 1
        }
      })

    uppy.on('upload-success', (file, response) => {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = `${
        this.resourceValue
      }[photos_attributes][${nanoid()}][${file.type.replace(/\/.*/, '')}]`
      hiddenField.value = uploadedFileData(file, response)
      this.element.appendChild(hiddenField)
    })

    return uppy
  }
}
