import {Controller} from '@hotwired/stimulus'

// Connects to data-controller="home-visit-booking-form"
export default class extends Controller {
  static values = { price: Number }
  static targets = ['number', 'amount']

  connect() {
    this.calculateAmount()
  }

  calculateAmount() {
    const number = this.numberTarget.value
    this.amountTarget.value = this.priceValue * number
  }

  onNumberChange(event) {
    this.calculateAmount()
  }
}
