import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['roleSection', 'interestRadio']

  connect() {
    this.toggleRoleSection()
  }

  toggleRoleSection() {
    const selectedInterest = this.interestRadioTargets.find(
      radio => radio.checked
    )?.value
    console.log(selectedInterest)
    if (selectedInterest === 'yes') {
      this.roleSectionTarget.classList.remove('hidden')
    } else {
      this.roleSectionTarget.classList.add('hidden')
    }
  }
}
