import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="activity-form"
export default class extends Controller {
  static targets = ['price', 'netAmount', 'usdAmount', 'platformFeePercentage']

  connect() {
    // this.fetchExchangeRate() TODO: 今は利用しない
    this.calculateAmount()
  }

  calculateAmount() {
    const price = parseFloat(this.priceTarget.value) || 0
    const netAmount = this.calculateNet(price)
    this.netAmountTarget.textContent = netAmount.toFixed(0)
    this.convertToUSD(this.priceTarget.value)
  }

  // 手取り計算ロジック（例: プラットフォーム手数料を引く）
  calculateNet(activityPrice) {
    const feeRate =
      parseFloat(this.platformFeePercentageTarget.textContent) / 100
    return activityPrice * feeRate
  }

  // 為替レートAPIからレートを取得
  // TODO: ストライプで計算するようにする
  fetchExchangeRate() {
    fetch('https://v6.exchangerate-api.com/v6/****/latest/JPY')
      .then(response => response.json())
      .then(data => {
        this.exchangeRate = data.conversion_rates.USD
      })
      .catch(error => console.error('Error fetching exchange rate:', error))
  }

  convertToUSD(amount) {
    if (this.exchangeRate) {
      const usdAmount = amount * this.exchangeRate
      this.usdAmountTarget.textContent = usdAmount.toFixed(2)
    }
  }
}
