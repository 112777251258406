import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'searchSubmit', 'recruitmentSubmit']

  connect() {
    const form = this.formTarget
    const searchSubmit = this.searchSubmitTarget
    const recruitmentSubmit = this.recruitmentSubmitTarget

    searchSubmit.addEventListener('click', event => {
      event.preventDefault()
      form.action = '/company/guides'
      form.submit()
    })

    recruitmentSubmit.addEventListener('click', event => {
      event.preventDefault()
      form.action = '/company/recruitments/new'
      form.submit()
    })
  }
}
