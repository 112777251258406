import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['loading', 'content']

  connect() {
    this.hideLoading()

    this.element.addEventListener(
      'turbo:submit-start',
      this.showLoading.bind(this)
    )

    this.element.addEventListener(
      'turbo:frame-render',
      this.hideLoading.bind(this)
    )
  }

  showLoading() {
    this.loadingTarget.style.display = 'block'
    this.contentTarget.style.display = 'none'
  }

  hideLoading() {
    this.loadingTarget.style.display = 'none'
    this.contentTarget.style.display = 'block'
  }
}
