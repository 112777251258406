import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'

// Connects to data-controller="splide"
export default class extends Controller {
  static targets = ['carousel']
  static values = { options: Object }

  connect() {
    this.splide = new Splide(this.carouselTarget, this.optionsValue).mount()

    const slideCount = this.splide.Components.Elements.slides.length

    // スライドが1枚だけの場合は矢印を非表示にするクラスを追加
    if (slideCount === 1) {
      this.element.classList.add('hide-arrows')
    }
  }
}
