import {Controller} from '@hotwired/stimulus'

// Connects to data-controller="home-visit-booking-date-controller"
export default class extends Controller {
  static targets = ['date', 'checkBox']

  connect() {}

  onDateChange(event) {
    // 本当は日にちを入力されたら1つはチェックボックスを選択されていることを必須にしたい
  }

  onCheckBoxChange(event) {
    const checkBox = event.target;
    const dateField = this.dateTarget;

    // 第1希望の場合、フォーム遷移時に付与しているrequiredを維持
    if (dateField.id === 'first-date') {
      return; // ここで処理を終了する
    }

    if (checkBox.checked) {
      dateField.setAttribute('required', 'required');
    } else {
      dateField.removeAttribute('required');
    }
  }
}
