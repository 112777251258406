import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'kindSelect',
    'licenseGuidePrefectureSection',
    'guideNumberSection'
  ]

  connect() {
    this.toggle()
  }

  toggle() {
    const kind = this.kindSelectTarget.value
    if (kind === 'licensed') {
      this.show(this.licenseGuidePrefectureSectionTarget)
      this.show(this.guideNumberSectionTarget)
    } else {
      this.hide(this.licenseGuidePrefectureSectionTarget)
      this.hide(this.guideNumberSectionTarget)
    }
  }

  hide(element) {
    element.classList.add('hidden')
  }

  show(element) {
    element.classList.remove('hidden')
  }
}
