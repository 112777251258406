import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template']

  addRoute(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    )
    const newElement = document.createElement('div')
    newElement.innerHTML = content
    const newRoute = newElement.firstElementChild
    const positionInput = newRoute.querySelector("input[name*='[position]']")
    positionInput.value =
      this.element.querySelectorAll('[data-sortable-item]').length + 1
    this.element
      .querySelector('[data-sortable-group-name]')
      .appendChild(newRoute)
    this.updatePositions()
  }

  removeRoute(event) {
    event.preventDefault()
    let item = event.target.closest('.nested-fields')
    if (item.dataset.newRecord === 'true') {
      item.remove()
    } else {
      let destroyField = item.querySelector("input[name*='_destroy']")
      if (destroyField) {
        destroyField.value = 1
        item.style.display = 'none'
      }
    }
    this.updatePositions()
  }

  updatePositions() {
    const items = this.element.querySelectorAll('[data-sortable-item]')
    items.forEach((item, index) => {
      item.querySelector("input[name*='[position]']").value = index + 1
    })
  }
}
