import {Controller} from '@hotwired/stimulus'
import weekSelectPlugin from "flatpickr/dist/plugins/weekSelect/weekSelect";

// Connects to data-controller="home-visit-booking-confirm-form"
export default class extends Controller {
  static targets = ['select', 'textarea']

  connect() {
    this.displayNoteTextArea()
  }

  displayNoteTextArea() {
    const selectedValue = this.selectTarget.value
    const textarea = this.textareaTarget

    if (selectedValue !== 'other') {
      textarea.style.display = 'none'
    } else {
      textarea.style.display = 'block'
    }
  }

  onNumberChange(event) {
    this.displayNoteTextArea()
  }
}
