import { Controller } from '@hotwired/stimulus'

// ## How to use
// <div data-controller="form-reveal" data-form-reveal-checkbox-id-value="checkbox_id">
//   <%= box.check_box class: 'form-checkbox', data: { action: "form-reveal#toggleReveal" } %>
//   <div data-form-reveal-target="revealElement">Reveal this element</div>
// </div>

// Connects to data-controller="form-reveal"
export default class extends Controller {
  static targets = ['revealElement']
  static values = { checkboxId: String }

  connect() {
    this.toggleReveal()
  }

  toggleReveal() {
    console.log('toggleReveal')
    // HTMLから渡されたチェックボックスIDを使用して要素を取得
    const checkbox = document.getElementById(this.checkboxIdValue)

    if (checkbox && checkbox.checked) {
      this.revealElementTarget.style.display = 'block'
    } else {
      this.revealElementTarget.style.display = 'none'
    }
  }
}
