import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['prefectureSelect', 'citySelect']

  connect() {}

  filterCities() {
    const prefectureId = this.prefectureSelectTarget.value
    const url = `/api/prefectures/${prefectureId}/cities`
    const locale = this.prefectureSelectTarget.dataset.locale

    fetch(url)
      .then(response => response.json())
      .then(data => {
        let options = data.map(
          city =>
            `<option value='${city.id}'>${
              locale === 'en' ? city.name : city.name_ja
            }</option>`
        )
        options.unshift(
          locale === 'en'
            ? `<option value=''>Select...</option>`
            : `<option value=''>選択してください</option>`
        )
        this.citySelectTarget.innerHTML = options.join('')
      })
  }
}
