import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    this.updatePositions()
  }

  updatePositions() {
    const items = this.element.querySelectorAll('[data-sortable-item]')
    items.forEach((item, index) => {
      item.querySelector("input[name*='[position]']").value = index + 1
    })
  }
}
