import { AwsS3 } from 'uppy'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

export function uppyInstance({ id, types, max, autoProceed = true }) {
  const uppy = new Uppy({
    id: id,
    autoProceed: autoProceed,
    restrictions: {
      allowedFileTypes: types,
      maxNumberOfFiles: max
    }
    // locale: Japanese, TODO: 多言語化
  })

  uppy.use(AwsS3, {
    companionUrl: '/' // will call Shrine's presign endpoint mounted on `/s3/params`
  })

  return uppy
}

export function uploadedFileData(file) {
  const id = file.meta['key'].match(/^image_cache\/(.+)/)[1] // object key without prefix

  return JSON.stringify(fileData(file, id))
}

// constructs uploaded file data in the format that Shrine expects
function fileData(file, id) {
  return {
    id: id,
    storage: 'image_cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type
    }
  }
}
