import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'firstInput',
    'firstPreview',
    'secondInput',
    'secondPreview',
    'thirdInput',
    'thirdPreview',
    'fourthInput',
    'fourthPreview'
  ]

  preview(event) {
    const input = event.target
    let previewTarget

    switch (input.dataset.target) {
      case 'image-preview.firstInput':
        previewTarget = this.firstPreviewTarget
        break
      case 'image-preview.secondInput':
        previewTarget = this.secondPreviewTarget
        break
      case 'image-preview.thirdInput':
        previewTarget = this.thirdPreviewTarget
        break
      case 'image-preview.fourthInput':
        previewTarget = this.fourthPreviewTarget
        break
    }

    // Clear previous preview
    previewTarget.innerHTML = ''

    // If there is a file selected, create a preview
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      reader.onload = e => {
        const img = document.createElement('img')
        img.src = e.target.result
        // img.width = 200 // Set the desired width for the preview
        img.classList.add(
          'h-full',
          'w-full',
          'object-cover',
          'object-center',
          'rounded-lg'
        )
        previewTarget.appendChild(img)
      }
      reader.readAsDataURL(input.files[0])
    }
  }
}
