import { Controller } from '@hotwired/stimulus'
import Dashboard from '@uppy/dashboard'
import { uploadedFileData, uppyInstance } from '../utils/uppy'
import { nanoid } from 'nanoid'

export default class extends Controller {
  static targets = ['input']
  static values = { types: Array, resource: String, max: String }

  connect() {
    this.uppy = this.createUppy()
  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
      id: this.inputTarget.id,
      types: this.typesValue,
      max: this.maxValue
    }).use(Dashboard, {
      target: this.inputTarget.parentNode,
      inline: true,
      height: 300,
      replaceTargetContent: true,
      hideProgressAfterFinish: true
    })

    uppy.on('upload-success', (file, response) => {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = `${
        this.resourceValue
      }[videos_attributes][${nanoid()}][${file.type.replace(/\/.*/, '')}]`
      hiddenField.value = uploadedFileData(file, response)
      this.element.appendChild(hiddenField)
    })

    return uppy
  }
}
