import { Controller } from '@hotwired/stimulus'
import { Loader } from '@googlemaps/js-api-loader'

export default class extends Controller {
  static values = {
    address: String,
    apiKey: String
  }

  connect() {
    this.loadMap()
  }

  loadMap() {
    const loader = new Loader({
      apiKey: this.apiKeyValue,
      version: 'weekly'
    })

    loader.load().then(() => {
      this.initMap()
    })
  }

  initMap() {
    // @googlemaps/js-api-loaderがGoogle Maps APIのスクリプトを非同期でロードし、グローバルgoogleオブジェクトを使えるようになる
    // eslint-disable-next-line no-undef
    const geocoder = new google.maps.Geocoder()
    const address = this.addressValue

    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        // eslint-disable-next-line no-undef
        const map = new google.maps.Map(this.element, {
          zoom: 15,
          center: results[0].geometry.location
        })
        // eslint-disable-next-line no-undef
        new google.maps.Marker({
          map: map,
          position: results[0].geometry.location
        })
      } else {
        alert('Geocode was not successful for the following reason: ' + status)
      }
    })
  }
}
